<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-loading-mask {
  z-index: 10 !important;
}
::v-deep .page-header {
  z-index: 20 !important;
}
</style>